<template>
  <div
    class="drag-handle h-30px w-20px rounded-md flex-align justify-center hover:bg-gray/10 text-5"
    :class="{ disabled }"
  >
    <Icon name="ic:round-drag-indicator" />
  </div>
</template>

<script lang="ts">
export default defineComponent({
  name: "GrabHandle",
  props: {
    disabled: Boolean,
  },
});
</script>

<style lang="scss">
.drag-handle {
  &:not(.disabled) {
    cursor: move;
    cursor: -webkit-grabbing;
  }
  &.disabled {
    cursor: not-allowed;
    opacity: 0.6;
  }
}
</style>
